/** @jsx h */
import { render, component, h } from 'literaljs';
import './index.scss';

// Components
import Content from './components/Content';

const AppLayout = component({
	render() {
		return (
			<div class="App">
				<Content />
			</div>
		);
	}
});

render(AppLayout, 'root', { showMenu: false });
