/** @jsx h */
import { component, h } from 'literaljs';
import './index.scss';

export default component({
	render() {
		return (
			<div class="Logo">
				<div class="L">L</div>
				<div class="J">J</div>
				<div class="S">S</div>
			</div>
		);
	}
});
