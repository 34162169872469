/** @jsx h */
import { component, h } from 'literaljs';
import './index.scss';

import Logo from '../Logo';
import Title from '../Title';

export default component({
	methods() {
		return {
			toggleMenu() {
				return this.setStore({ showMenu: !this.getStore().showMenu });
			},
			menuClass() {
				return this.getStore().showMenu ? 'menu -show' : 'menu -hide';
			},
			menuItems() {
				return [
					{ href: "#introduction", text: "Introduction" },
					{ href: "#features", text: "Features" },
					{ href: "#getting-started", text: "Getting Started" },
					{ href: "#installation", text: "Installation" },
					{ href: "#example-apps", text: "Example Apps" },
					{ href: "#markup-syntax", text: "Markup Syntax" },
					{ href: "#render-function", text: "Render" },
					{ href: "#component-function", text: "Component" },
					{ href: "#literaljs-router", text: "LiteralJS-Router" },
					{ href: "#deployment", text: "Deployment" }
				];
			}
		};
	},
	render() {
		return (
			<div class="Navbar">
				<div class="top">
					<div class="title">
						<Logo />
						<Title />
					</div>
					<div class="version">
						Version
						<span class="blue"> 7.0.2</span>
					</div>
					<button
						class="menu-button"
						events={{ click: this.toggleMenu }}
					>
						<span>-</span>
						<span>-</span>
						<span>-</span>
					</button>
				</div>
				<div class={this.menuClass()}>
					{this.menuItems().map((item) => (
						<a href={item.href} events={{ click: this.toggleMenu }}>
							{item.text}
						</a>
					))}
					<div class="links">
						<a href="https://gitlab.com/mikeysax/LiteralJS">
							Gitlab
						</a>
						<a href="https://www.npmjs.com/package/literaljs">
							NPM
						</a>
					</div>
				</div>
			</div>
		);
	},
});
