let ROOT_NODE, SAVED_TREE, CURRENT_TREE;
let GLOBAL_STORE = {};
let COMPONENT_STATE = {};
const EVENTS_KEY = 'eid';
const DELEGATED_EVENTS_ADDED = [];
const EVENTS_CACHE = {};
const LIFECYCLE_QUEUE = [];
const NON_DELEGATED_EVENTS = [
	'abort',
	'blur',
	'error',
	'focus',
	'load',
	'mouseenter',
	'mouseleave',
	'resize',
	'scroll',
	'unload'
];
const STRING_TYPE = 'string';
const NUMBER_TYPE = 'number';
const BOOLEAN_TYPE = 'boolean';
const UNDEFINED_TYPE = 'undefined';
const OBJECT_TYPE = 'object';
const FUNCTION_TYPE = 'function';
const EVENTS_TYPE = 'events';
const STYLE_TYPE = 'style';
const VALUE_TYPE = 'value';

function isArray(val) {
	return Array.isArray(val);
}

function generateUUID() {
	return (
		Math.random()
			.toString(36)
			.substring(2) + new Date().getTime().toString(36)
	);
}

function flatten(out, a) {
	for (let i = 0, len = a.length; i < len; i++) {
		if (isArray(a[i])) flatten(out, a[i]);
		else out.push(a[i]);
	}
	return out;
}

function assign(out, a) {
	for (let i in a) out[i] = a[i];
	return out;
}

function merge(out, a, b) {
	return assign(assign(out, a), b);
}

export function h(el, attr, ...children) {
	return {
		e: el,
		a: assign({}, attr),
		c: flatten([], children)
	};
}

export function render(components, documentNodeID, state = {}) {
	// Initialize application state
	GLOBAL_STORE = assign({}, state);
	// Cache initial tree for re-instanting with new states
	// Cache the initial tree for diffing later
	SAVED_TREE = components;
	// Grab root node to mount application on
	ROOT_NODE = document.getElementById(documentNodeID);
	// Create first component tree
	CURRENT_TREE = generateTreeVN(components());
	// Append the new tree to our application for the first paint
	appendChild(ROOT_NODE, CURRENT_TREE);
	// Initial Lifecycle Trigger
	shiftLifcycleQueue();
}

export function component({
	name = '',
	state = {},
	mounted = function() {},
	updated = function() {},
	unmounted = function() {},
	methods = function() {},
	render = function() {}
} = {}) {
	const componentUUID = generateUUID();
	return function(props = {}) {
		return {
			id: componentUUID,
			init: function() {
				this.id = componentUUID;
				this.name = name + (props.key ? '_' + props.key : '');
				this.key = this.id + (this.name || '');
				this.props = props;
				this.store = GLOBAL_STORE;
				this.getStore = getStore;
				this.setStore = setStore.bind(this);
				this.getState = getState.bind(this);
				this.setState = setState.bind(this);
				this.createState = function() {
					if (typeof COMPONENT_STATE[this.key] === UNDEFINED_TYPE) {
						COMPONENT_STATE[this.key] = state;
					}
				}.bind(this)();
				this.deleteState = function() {
					delete COMPONENT_STATE[this.key];
				}.bind(this);
				this.mounted = mounted.bind(this);
				this.updated = updated.bind(this);
				this.unmounted = unmounted.bind(this);
				bindMethods(this, methods);
				this.render = render.bind(this);
				return this;
			}
		};
	};
}

function getStore() {
	return assign({}, GLOBAL_STORE);
}

function setStore(newStore, callback) {
	if (typeof newStore === FUNCTION_TYPE) {
		newStore = newStore(GLOBAL_STORE);
	}
	GLOBAL_STORE = merge({}, GLOBAL_STORE, newStore);
	initiateDiff();
	if (typeof callback === FUNCTION_TYPE) callback.bind(this)();
}

function getState() {
	return assign({}, COMPONENT_STATE[this.key]);
}

function setState(newState, callback) {
	if (typeof newState === FUNCTION_TYPE) {
		newState = newState(COMPONENT_STATE[this.key]);
	}
	COMPONENT_STATE[this.key] = merge({}, COMPONENT_STATE[this.key], newState);
	initiateDiff();
	if (typeof callback === FUNCTION_TYPE) callback.bind(this)();
}

function bindMethods(component, methods) {
	if (typeof methods === FUNCTION_TYPE) {
		methods = methods.bind(component)();
	}

	for (let func in methods) {
		component[func] = methods[func].bind(component);
	}
}

function generateTreeVN(newC) {
	if (typeof newC === UNDEFINED_TYPE) {
		return newC;
	} else if (typeof newC.e === FUNCTION_TYPE) {
		// For Components in the application to inject props.
		const props = assign({}, newC.a.props);
		delete newC.a.props;
		newC = newC.e(props);
	}

	if (typeof newC.init === FUNCTION_TYPE) {
		// For the Root Component And Componets
		// Initialize The Component
		const cObj = new newC.init();
		// Render Component Markup
		newC = cObj.render();

		if (typeof newC === OBJECT_TYPE) {
			// Attach Id and LC Methods To Render Object
			newC.id = cObj.id;
			newC.m = cObj.mounted;
			newC.up = cObj.updated;
			newC.un = cObj.unmounted;
			newC.d = cObj.deleteState;
		}
	}

	if (isArray(newC.c)) {
		for (let i = 0, len = newC.c.length; i < len; i++) {
			newC.c[i] = generateTreeVN(newC.c[i]);
		}
	}

	return newC;
}

function createDomNode(data) {
	const type = typeof data;
	if (type === STRING_TYPE || type === NUMBER_TYPE) {
		return document.createTextNode(data);
	} else if (type === BOOLEAN_TYPE || type === UNDEFINED_TYPE) {
		return document.createTextNode('');
	} else {
		const newE = document.createElement(data.e);

		if (typeof data.a === OBJECT_TYPE) {
			const keys = Object.keys(data.a);
			for (let i = keys.length; i--; ) {
				if (keys[i] === EVENTS_TYPE) {
					data.eid = generateUUID();
					addEvents(data.eid, data.a[keys[i]], newE);
				} else if (keys[i] === STYLE_TYPE) {
					addStyles(data.a[keys[i]], newE);
				} else {
					newE.setAttribute(keys[i], data.a[keys[i]]);
				}
			}
		}

		pushLifecycle(data, 'm');

		if (isArray(data.c)) {
			for (let i = 0, len = data.c.length; i < len; i++) {
				appendChild(newE, data.c[i]);
			}
		}

		return newE;
	}
}

function getEventUUID(element) {
	if (element.parentNode.nodeName === '#document') return;
	const eid = element.getAttribute('data-' + EVENTS_KEY);
	if (typeof eid === STRING_TYPE) return eid;
	return getEventUUID(element.parentNode);
}

function addEvents(eid, eventObj, targetNode) {
	// Generate UUID here for event functions
	// Send event functions into a cache for lookup by UUID
	// UUID is pulled during Event Delegation and the appropriate
	// event is picked by type.
	targetNode.setAttribute('data-' + EVENTS_KEY, eid);
	EVENTS_CACHE[eid] = eventObj;

	// Prevent certain events from being delegated due to them not bubbling.
	const keys = Object.keys(eventObj);
	for (let i = keys.length; i--; ) {
		if (NON_DELEGATED_EVENTS.includes(keys[i])) {
			// Attach event directly if non-bubbling / non-delegated
			targetNode.addEventListener(keys[i], EVENTS_CACHE[eid][keys[i]]);
		} else if (!DELEGATED_EVENTS_ADDED.includes(keys[i])) {
			// Added listener type to keep track of what kinds of events
			// are added to the root node.
			DELEGATED_EVENTS_ADDED.push(keys[i]);
			ROOT_NODE.addEventListener(keys[i], function(e) {
				const uuid = getEventUUID(e.target);
				if (typeof uuid === STRING_TYPE && EVENTS_CACHE[uuid]) {
					const event = EVENTS_CACHE[uuid][e.type];
					if (typeof event === FUNCTION_TYPE) event(e);
				}
			});
		}
	}
}

function addStyles(styles, e) {
	if (typeof styles === UNDEFINED_TYPE) return;
	const keys = Object.keys(styles);
	for (let i = keys.length; i--; ) {
		e.style[keys[i]] = styles[keys[i]];
	}
}

function initiateDiff(uuid) {
	// Generate new and old trees
	const oldTree = assign({}, CURRENT_TREE);
	const newTree = generateTreeVN(SAVED_TREE());
	// Cache new tree for future diffs
	CURRENT_TREE = newTree;
	// Initiate diff of trees...
	diffAndPatch(ROOT_NODE, ROOT_NODE.firstChild, newTree, oldTree);
	// Trigger Lifecycle Methods...
	shiftLifcycleQueue();
}

function diffAndPatch(parentNode, targetNode, newVN, oldVN) {
	const nType = typeof newVN;
	const oType = typeof oldVN;

	if (oType === UNDEFINED_TYPE) {
		appendChild(parentNode, newVN);
	} else if (nType === UNDEFINED_TYPE) {
		unmountNodeAndChildren(oldVN);
		targetNode.remove();
	} else if (nodesNotEqual(newVN, oldVN, nType, oType)) {
		unmountNodeAndChildren(oldVN);
		parentNode.replaceChild(createDomNode(newVN), targetNode);
	} else if (nType === OBJECT_TYPE) {
		updateNodes(targetNode, newVN, oldVN);

		let lastChild;
		for (let i = oldVN.c.length; i--; ) {
			if (typeof newVN.c[i] !== UNDEFINED_TYPE) break;
			lastChild = targetNode.lastChild;
			if (typeof lastChild === OBJECT_TYPE) {
				unmountNodeAndChildren(oldVN.c[i]);
				lastChild.remove();
			}
		}

		// Recurse through children and diff.
		for (let i = 0, len = newVN.c.length; i < len; i++) {
			diffAndPatch(
				targetNode,
				targetNode.childNodes[i],
				newVN.c[i],
				oldVN.c[i]
			);
		}
	}
}

function appendChild(parentNode, newNode) {
	parentNode.appendChild(createDomNode(newNode));
}

function nodesNotEqual(newVN, oldVN, nType, oType) {
	return (
		nType !== oType ||
		newVN.e !== oldVN.e ||
		newVN.id !== oldVN.id ||
		((nType === STRING_TYPE || nType === NUMBER_TYPE) && newVN !== oldVN)
	);
}

function updateNodes(targetNode, newVN, oldVN) {
	pushLifecycle(newVN, 'up');

	const keys = Object.keys(newVN.a);
	let oldVal, newVal;
	for (let i = keys.length; i--; ) {
		newVal = newVN.a[keys[i]];
		oldVal = oldVN.a[keys[i]];
		if (keys[i] === STYLE_TYPE) {
			if (stylesAreEqual(newVal, oldVal)) continue;
			targetNode.removeAttribute(keys[i]);
			addStyles(newVal, targetNode);
		} else if (keys[i] === EVENTS_TYPE) {
			removeIndividuallyBoundEvents(targetNode, oldVN.eid);
			deleteCachedEvent(oldVN.eid);
			newVN.eid = generateUUID();
			addEvents(newVN.eid, newVal, targetNode);
		} else if (keys[i] === VALUE_TYPE && newVal !== oldVal) {
			targetNode.value = newVal;
		} else if (typeof oldVal === UNDEFINED_TYPE || newVal !== oldVal) {
			targetNode.setAttribute(keys[i], newVal);
		}
	}
}

function unmountNodeAndChildren(node) {
	// Recursivly unmounts all children.
	if (typeof node === UNDEFINED_TYPE) return;
	pushLifecycle(node, 'un');
	pushLifecycle(node, 'd');
	deleteCachedEvent(node.eid);
	if (isArray(node.c)) {
		for (let i = node.c.length; i--; ) {
			unmountNodeAndChildren(node.c[i]);
		}
	}
}

function deleteCachedEvent(eid) {
	if (typeof eid === STRING_TYPE) delete EVENTS_CACHE[eid];
}

function removeIndividuallyBoundEvents(targetNode, eid) {
	const keys = Object.keys(EVENTS_CACHE[eid]);
	for (let i = keys.length; i--; ) {
		if (NON_DELEGATED_EVENTS.includes(keys[i])) {
			targetNode.removeEventListener(keys[i], EVENTS_CACHE[eid][keys[i]]);
		}
	}
}

function stylesAreEqual(newStyle, oldStyle) {
	const newType = typeof newStyle;
	const oldType = typeof oldStyle;

	return (
		(newType === OBJECT_TYPE &&
			oldType === OBJECT_TYPE &&
			JSON.stringify(newStyle) === JSON.stringify(oldStyle)) ||
		(newType === UNDEFINED_TYPE && oldType === UNDEFINED_TYPE)
	);
}

function shiftLifcycleQueue() {
	let lc;
	while ((lc = LIFECYCLE_QUEUE.shift())) lc();
}

function pushLifecycle(node, lcName) {
	if (typeof node === OBJECT_TYPE && typeof node[lcName] === FUNCTION_TYPE) {
		LIFECYCLE_QUEUE.push(node[lcName]);
	}
}

module.exports = {
	component,
	render,
	h
};
