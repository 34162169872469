/** @jsx h */
import { component, h } from 'literaljs';
import './index.scss';

export default component({
	render() {
		return (
			<h1 class="Title">
				<span class="Literal">Literal</span>
				<span class="JS">JS</span>
			</h1>
		);
	}
});
